// Generated by Framer (4c611f3)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {Gh7LFP7OO: {hover: true}};

const cycleOrder = ["Gh7LFP7OO"];

const variantClassNames = {Gh7LFP7OO: "framer-v-1ggofvc"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, title, width, ...props}) => { return {...props, UDDfHBzdc: title ?? props.UDDfHBzdc ?? "Continue"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, UDDfHBzdc, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "Gh7LFP7OO", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnter1mqyn9a = activeVariantCallback(async (...args) => {
setVariant("Gh7LFP7OO")
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-nDdge", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1ggofvc", className)} data-border data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"Gh7LFP7OO"} onMouseEnter={onMouseEnter1mqyn9a} ref={ref} style={{"--border-bottom-width": "1px", "--border-color": "rgb(128, 128, 128)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", background: "linear-gradient(88deg, #433e44 0%, #474751 100%)", borderBottomLeftRadius: 44, borderBottomRightRadius: 44, borderTopLeftRadius: 44, borderTopRightRadius: 44, ...style}} {...addPropertyOverrides({"Gh7LFP7OO-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--framer-font-size": "12px", "--framer-text-color": "var(--extracted-r6o4lv)"}}>Continue</motion.p></React.Fragment>} className={"framer-4ex1yr"} layoutDependency={layoutDependency} layoutId={"N3JHChinP"} style={{"--extracted-r6o4lv": "var(--token-e321b03f-d732-43f9-bbf7-842b7ca11fae, rgb(255, 255, 255))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={UDDfHBzdc} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-nDdge [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-nDdge .framer-pbzuvb { display: block; }", ".framer-nDdge .framer-1ggofvc { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 28px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 113px; will-change: transform; }", ".framer-nDdge .framer-4ex1yr { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-nDdge .framer-v-1ggofvc .framer-1ggofvc { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-nDdge .framer-1ggofvc { gap: 0px; } .framer-nDdge .framer-1ggofvc > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-nDdge .framer-1ggofvc > :first-child { margin-left: 0px; } .framer-nDdge .framer-1ggofvc > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 28
 * @framerIntrinsicWidth 113
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"FZXd2bcdi":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"UDDfHBzdc":"title"}
 */
const FramersXoNuIpSl: React.ComponentType<Props> = withCSS(Component, css, "framer-nDdge") as typeof Component;
export default FramersXoNuIpSl;

FramersXoNuIpSl.displayName = "b2b-button";

FramersXoNuIpSl.defaultProps = {height: 28, width: 113};

addPropertyControls(FramersXoNuIpSl, {UDDfHBzdc: {defaultValue: "Continue", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramersXoNuIpSl, [])